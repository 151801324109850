var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-squares"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.dashboard")))])])])]}}],null,false,963293763)}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-placeholder-3"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.addresses")))])])])]}}],null,false,3780860744)}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/offer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-checking"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.offers")))])])])]}}],null,false,257605130)}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-infographic"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.projects")))])])])]}}],null,false,1085464201)}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.invoices")))])])])]}}],null,false,1675249607)}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/expense"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-interface-10"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.expense")))])])])]}}],null,false,4259767756)}):_vm._e(),_c('router-link',{attrs:{"to":"/timesheet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-chronometer"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.timesheet")))])])])]}}])}),(_vm.currentUser.role !== 'employee')?_c('router-link',{attrs:{"to":"/time-overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-dashboard"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.timeOverview")))])])])]}}],null,false,2170509127)}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/report"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-analytics-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("menu.report")))])])])]}}],null,false,3844548099)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }