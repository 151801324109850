var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("menu.product"))+" ")])])])]}}],null,false,3620774684)}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('router-link',{attrs:{"to":"/settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("menu.settings"))+" ")])])])]}}],null,false,2028693959)}):_vm._e(),_c('li',{staticClass:"menu-item"},[_c('div',{staticClass:"menu-link",on:{"click":_vm.toggleFullScreen}},[_c('i',{staticClass:"menu-icon flaticon2-expand"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t("menu.fullscr"))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }